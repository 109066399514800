import "./Landing.css";

const ticketCards = [
  {
    src: "/images/landing/ticket-cards/analytics.svg",
    title: "Analytics",
    text: "Base your decisions on data insights",
  },
  {
    src: "/images/landing/ticket-cards/ticket-sales.svg",
    title: "Ticket Sales",
    text: "Offer more than just your tickets for sale",
  },
  {
    title: "Simple Creation",
    src: "/images/landing/ticket-cards/simple-creation.svg",
    text: "Easily create and edit events ",
  },
];

const landingSections = [
  {
    title: "Design Your Event With a Personal Touch",
    src: "/images/landing/sections/personal-touch.png",
    points: [
      "Present your fans with an exclusive ambassador program.",
      "Have the flexibility to decide when you receive your funds.",
      "Connect seamlessly with your key audience using our loyalty tool.",
    ],
  },
  {
    title: "Understand Your Audience",
    src: "/images/landing/sections/audience.png",
    points: [
      "Analyze your data to effectively target the right audience.",
      "Initiate the development of your unique data and user profiles.",
      "Easy communication between sales platforms.",
    ],
  },
];

const Landing = () => {
  return (
    <div className="landing">
      <header className="landing__header">
        <div className="container">
          <div className="landing__header-logo">
            <img src="/images/logo.svg" alt="logo" />
          </div>
          <div className="landing__header-hero">
            <h4 className="landing__header-subtitle landing__header-subtitle_welcome">
              Welcome to Fancee Tickets
            </h4>
            <h2 className="landing__header-title">
              Revolutionize Your Ticketing Experience
            </h2>
            <h4 className="landing__header-subtitle">
              A customizable platform to effortlessly sell tickets and engage
              your audience
            </h4>
          </div>
        </div>
      </header>
      <div className="container">
        <section className="landing__tickets">
          <h2 className="landing__title">Get Started With Fancee Tickets</h2>
          <div className="landing__tickets-cards">
            {ticketCards.map(({ src, text, title }) => (
              <div key={title + text} className="landing__tickets-card">
                <img src={src} alt={title} />
                <h5>{title}</h5>
                <p>{text}</p>
              </div>
            ))}
          </div>
        </section>
        {landingSections.map(({ points, src, title }) => (
          <section className="landing__section" key={title}>
            <img alt={title} src={src} />
            <div className="landing__section-hero">
              <h2 className="landing__title">{title}</h2>
              {points.map((text) => (
                <div className="landing__section-point">
                  <img alt="Tick Circle" src="/images/tick-circle.svg" />
                  <p>{text}</p>
                </div>
              ))}
            </div>
          </section>
        ))}
        <section className="landing__contact">
          <div className="landing__contact-wrapper">
            <form className="landing__contact-form">
              <h5>Get in Touch</h5>
              <h2 className="landing__title">Get You Consultation for Free</h2>
              <div className="landing__contact-fields">
                <input type="text" placeholder="Enter your email" />
                <button>Contact Me</button>
              </div>
            </form>
          </div>
        </section>
      </div>
      <footer className="landing__footer">
        <div className="landing__footer-container container">
          <div>
            <img src="/images/logo-white.svg" alt="logo" />
            <p>we are fancee doo</p>
          </div>
          <div>
            <p>Srebrnička 3, 11000 Beograd </p>
            <p>MB: 21940291</p>
            <p>PIB: 113886255</p>
            <p>Tickets with love</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Landing;
